@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.customer-container {
  background-color: #d9d9d9;
}
.customer-container .customer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}
.customer-container .customer-content h1 {
  color: #4d4d4d;
}
.customer-container .customer-content .customer-box-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 95%;
  margin-top: 40px;
}
.customer-container .customer-content .customer-box-container .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #4d4d4d;
  color: #d9d9d9;
  width: 30vh;
  height: 45vh;
  padding: 20px 20px;
  margin-top: 10px;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.customer-container .customer-content .customer-box-container .box .customer-photo {
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.customer-container .customer-content .customer-box-container .box .customer-photo img {
  width: 100%;
}
.customer-container .customer-content .customer-box-container .box .min-review {
  overflow: hidden;
}
.customer-container .customer-content .customer-box-container .box .full-review {
  overflow: visible;
  text-overflow: ellipsis;
}
.customer-container .customer-content .customer-box-container .box .read-more-btn {
  background: none;
  border: none;
  outline: none;
  color: #fbb03b;
  text-decoration: underline;
}
.customer-container .customer-content .customer-box-container .box .read-more-btn:hover {
  cursor: pointer;
  color: #4d4d4d;
}
.customer-container .customer-content .customer-box-container .box .puan-star img {
  width: 20px;
  margin: 5px;
}
.customer-container .customer-content .customer-box-container .box:hover {
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  top: -10px;
  color: #4d4d4d;
}

/********* MOBİLE ********/
@media (max-width: 480px) {
  .customer-container {
    flex-direction: column;
  }
  .customer-container .customer-content h1 {
    text-align: center;
  }
  .customer-container .customer-content .customer-box-container .box {
    width: 80%;
  }
  .customer-container .customer-content .customer-box-container .box .customer-photo {
    width: 80px;
    height: 80px;
  }
  .customer-container .customer-content .customer-box-container .box h3 {
    font-size: 6vw;
  }
  .customer-container .customer-content .customer-box-container .box .puan-star img {
    width: 30px;
  }
}
/******************* TABLET ******************/
@media (min-width: 481px) and (max-width: 768px) {
  .customer-container {
    flex-direction: column;
  }
  .customer-container .customer-content h1 {
    text-align: center;
  }
  .customer-container .customer-content .customer-box-container .box {
    width: 80%;
  }
  .customer-container .customer-content .customer-box-container .box .customer-photo {
    width: 80px;
    height: 80px;
  }
  .customer-container .customer-content .customer-box-container .box h3 {
    font-size: 6vw;
  }
  .customer-container .customer-content .customer-box-container .box .puan-star img {
    width: 30px;
  }
}