@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.contact-container .contact-content {
  padding: 100px 0px;
}
.contact-container .contact-content .iframe-container iframe {
  width: 100%;
  height: 50vh;
  border: none;
}
.contact-container .contact-content .box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}
.contact-container .contact-content .box-container .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 35vh;
  background-color: #d9d9d9;
  text-align: center;
  position: relative;
  top: 0;
  transition: all 0.3s ease 0s;
}
.contact-container .contact-content .box-container .box img {
  width: 5vw;
}
.contact-container .contact-content .box-container .box h4 {
  font-size: 1.5vw;
  color: #4d4d4d;
}
.contact-container .contact-content .box-container .box p {
  color: #4d4d4d;
  font-weight: 400;
}
.contact-container .contact-content .box-container .box button {
  background: none;
  border: none;
  outline: none;
}
.contact-container .contact-content .box-container .box button img {
  width: 40px;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.contact-container .contact-content .box-container .box:hover {
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  top: -10px;
}
.contact-container .contact-content .box-container .box:hover:before {
  display: none;
}
.contact-container .contact-content .box-container .box:hover > button > img {
  transform: rotate(-90deg);
}
.contact-container .contact-content .box-container .box-border-one::before {
  content: "";
  width: 250px;
  height: 35vh;
  position: absolute;
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  left: -20px;
  top: -20px;
  z-index: -1;
}
.contact-container .contact-content .box-container .box-border-two::before {
  content: "";
  width: 250px;
  height: 35vh;
  position: absolute;
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  right: -20px;
  bottom: -20px;
  z-index: -1;
}

/******** MOBİLE ***********/
@media (max-width: 480px) {
  .contact-container .contact-content .box-container {
    flex-direction: column;
  }
  .contact-container .contact-content .box-container .box {
    width: 80%;
  }
  .contact-container .contact-content .box-container .box img {
    width: 15vw;
  }
  .contact-container .contact-content .box-container .box h4 {
    font-size: 5vw;
  }
  .contact-container .contact-content .box-container .box-border-one::before {
    display: none;
  }
  .contact-container .contact-content .box-container .box-border-two::before {
    display: none;
  }
}
/******************* TABLET *******************/
@media (min-width: 481px) and (max-width: 768px) {
  .contact-container .contact-content .box-container {
    flex-direction: column;
  }
  .contact-container .contact-content .box-container .box {
    width: 80%;
  }
  .contact-container .contact-content .box-container .box img {
    width: 15vw;
  }
  .contact-container .contact-content .box-container .box h4 {
    font-size: 5vw;
  }
  .contact-container .contact-content .box-container .box-border-one::before {
    display: none;
  }
  .contact-container .contact-content .box-container .box-border-two::before {
    display: none;
  }
}