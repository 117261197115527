@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.footer-container {
  background-color: #d9d9d9;
}
.footer-container .footer-Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
}
.footer-container .footer-Content p {
  color: #4d4d4d;
}
.footer-container .footer-Content .footer-link {
  margin-left: 50px;
}
.footer-container .footer-Content .footer-link a {
  text-decoration: none;
}
.footer-container .footer-Content .footer-link a img {
  width: 40px;
}

/****** MOBİLE **********/
@media (max-width: 480px) {
  .footer-container .footer-Content {
    flex-direction: column;
  }
  .footer-container .footer-Content .footer-link {
    margin-top: 20px;
    margin-right: 50px;
  }
}
/******************** TABLET *******************/
@media (min-width: 481px) and (max-width: 768px) {
  .footer-container .footer-Content {
    flex-direction: column;
  }
  .footer-container .footer-Content .footer-link {
    margin-top: 20px;
    margin-right: 50px;
  }
}