@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.our_services-container {
  width: 100%;
  background-color: #d9d9d9;
  user-select: none;
}
.our_services-container .our_services-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}
.our_services-container .our_services-content h1 {
  color: #4d4d4d;
}
.our_services-container .our_services-content p {
  color: #4d4d4d;
}
.our_services-container .our_services-content .dropBox-container {
  width: 60%;
  margin-top: 30px;
}
.our_services-container .our_services-content .dropBox-container .drop-box {
  margin: 20px 0px;
  position: relative;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #4d4d4d;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn p {
  color: #d9d9d9;
  font-weight: 400;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon {
  width: 1.5vw;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon-deg {
  width: 1.5vw;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn:hover {
  cursor: pointer;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-detail-close {
  visibility: hidden;
  position: absolute;
  padding: 0px 20px;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-detail-open {
  visibility: visible;
  border-top: 3px solid #f7931e;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}
.our_services-container .our_services-content .dropBox-container .drop-box .drop-box-detail-open p {
  color: #4d4d4d;
}

/******* MOBİLE *********/
@media (max-width: 480px) {
  .our_services-container .our_services-content p {
    text-align: center;
    padding: 0 5px;
  }
  .our_services-container .our_services-content .dropBox-container {
    width: 90%;
  }
  .our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon {
    width: 5vw;
  }
  .our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon-deg {
    width: 5vw;
  }
}
/************** TABLET *******************/
@media (min-width: 481px) and (max-width: 768px) {
  .our_services-container .our_services-content p {
    text-align: center;
    padding: 0 5px;
  }
  .our_services-container .our_services-content .dropBox-container {
    width: 90%;
  }
  .our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon {
    width: 5vw;
  }
  .our_services-container .our_services-content .dropBox-container .drop-box .drop-box-header-btn .plus-icon-deg {
    width: 5vw;
  }
}