@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.our_features-container {
  width: 100%;
}
.our_features-container .our_fetaures-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}
.our_features-container .our_fetaures-content h1 {
  color: #d9d9d9;
}
.our_features-container .our_fetaures-content p {
  color: #d9d9d9;
}
.our_features-container .our_fetaures-content .features-img-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 100%;
}
.our_features-container .our_fetaures-content .features-img-container .features-box {
  width: 21vw;
  height: 50vh;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.our_features-container .our_fetaures-content .features-img-container .features-box .before-text {
  padding: 10px;
  visibility: hidden;
  position: relative;
  z-index: 10;
  transition: all 0.3 ease-in-out;
}
.our_features-container .our_fetaures-content .features-img-container .features-box .before-text .before-header {
  font-size: 1.6vw;
}
.our_features-container .our_fetaures-content .features-img-container .features-box .before-text p {
  text-align: start;
  font-size: 1vw;
  color: white;
}
.our_features-container .our_fetaures-content .features-img-container .features-box .before-text hr {
  width: 100%;
  margin: 10px 0px;
}
.our_features-container .our_fetaures-content .features-img-container .features-box::before {
  content: "";
  position: absolute;
  background: linear-gradient(to right, rgba(255, 136, 0, 0.7), rgba(255, 251, 0, 0.7));
  height: 0%;
  width: 100%;
  transition: all 0.3s ease-in-out;
  bottom: 0;
}
.our_features-container .our_fetaures-content .features-img-container .features-box:hover::before {
  content: "";
  position: absolute;
  background: linear-gradient(to right, rgba(255, 136, 0, 0.7), rgba(255, 251, 0, 0.7));
  height: 100%;
  z-index: 5;
}
.our_features-container .our_fetaures-content .features-img-container .features-box:hover .before-text {
  visibility: visible;
}
.our_features-container .our_fetaures-content .features-img-container .features-box-one {
  background-image: url("../../../Assets/Images/our-feature.webp");
}
.our_features-container .our_fetaures-content .features-img-container .features-box-two {
  background-image: url("../../../Assets/Images/our-feature-two.webp");
}
.our_features-container .our_fetaures-content .features-img-container .features-box-three {
  background-image: url("../../../Assets/Images/our-feature-three.webp");
}
.our_features-container .our_fetaures-content .features-img-container .features-box-four {
  background-image: url("../../../Assets/Images/our-feature-four.webp");
}

/******* MOBİLE *********/
@media (max-width: 480px) {
  .our_features-container .our_fetaures-content p {
    text-align: center;
    padding: 0px 20px;
  }
  .our_features-container .our_fetaures-content .features-img-container {
    flex-direction: column;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box {
    width: 90%;
    margin-top: 30px;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box .before-text .before-header {
    font-size: 6vw;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box .before-text p {
    font-size: 4vw;
  }
}
/**************** TABLET *******************/
@media (min-width: 481px) and (max-width: 768px) {
  .our_features-container .our_fetaures-content p {
    text-align: center;
    padding: 0px 20px;
  }
  .our_features-container .our_fetaures-content .features-img-container {
    flex-direction: column;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box {
    width: 90%;
    margin-top: 30px;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box .before-text .before-header {
    font-size: 5vw;
  }
  .our_features-container .our_fetaures-content .features-img-container .features-box .before-text p {
    font-size: 3vw;
  }
}