@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.navbar-container {
  background-color: #d9d9d9;
  padding: 5px 0px;
  position: fixed;
  width: 100vw;
  z-index: 100;
}
.navbar-container .navbar-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.navbar-container .navbar-content .logo-container {
  width: 5vw;
}
.navbar-container .navbar-content .logo-container img {
  width: 100%;
}
.navbar-container .navbar-content .link-container .nav-media-btn {
  display: none;
}
.navbar-container .navbar-content .link-container .link-content-close {
  width: 60vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.navbar-container .navbar-content .link-container .link-content-close li {
  list-style: none;
}
.navbar-container .navbar-content .link-container .link-content-close li a {
  text-decoration: none;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 1vw;
}
.navbar-container .navbar-content .link-container .link-content-close li a:hover {
  border-top: 1px solid #fbb03b;
  cursor: pointer;
}
.navbar-container .navbar-content .link-container .link-content-close li button {
  outline: none;
  border: none;
  background: linear-gradient(#d9d9d9, #d9d9d9) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat, linear-gradient(90deg, transparent 0%, #f7931e 0%, #fbb03b 88%, #f7931e 97%, #fbb03b 100%);
  border-radius: 0px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 1.2vw;
}
.navbar-container .navbar-content .link-container .link-content-close li button:hover {
  cursor: pointer;
  background: linear-gradient(#fbb03b, #f7931e) 50% 50%/calc(100% - 4px) calc(100% - 4px);
  color: #d9d9d9;
}
.navbar-container .navbar-content .link-container .link-content-close li button:active {
  background: linear-gradient(#f7931e, #fbb03b) 50% 50%/calc(100% - 4px) calc(100% - 4px);
  color: #d9d9d9;
}
.navbar-container .navbar-content .link-container .link-content {
  width: 60vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.navbar-container .navbar-content .link-container .link-content li {
  list-style: none;
}
.navbar-container .navbar-content .link-container .link-content li a {
  text-decoration: none;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 1vw;
}
.navbar-container .navbar-content .link-container .link-content li a:hover {
  border-top: 1px solid #fbb03b;
  cursor: pointer;
}
.navbar-container .navbar-content .link-container .link-content li button {
  outline: none;
  border: none;
  background: linear-gradient(#d9d9d9, #d9d9d9) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat, linear-gradient(90deg, transparent 0%, #f7931e 0%, #fbb03b 88%, #f7931e 97%, #fbb03b 100%);
  border-radius: 0px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 1.2vw;
}
.navbar-container .navbar-content .link-container .link-content li button:hover {
  cursor: pointer;
  background: linear-gradient(#fbb03b, #f7931e) 50% 50%/calc(100% - 4px) calc(100% - 4px);
  color: #d9d9d9;
}
.navbar-container .navbar-content .link-container .link-content li button:active {
  background: linear-gradient(#f7931e, #fbb03b) 50% 50%/calc(100% - 4px) calc(100% - 4px);
  color: #d9d9d9;
}

/*************** MOBİLE ******************/
@media (max-width: 480px) {
  .navbar-container .navbar-content {
    justify-content: space-between;
  }
  .navbar-container .navbar-content .logo-container {
    width: 20vw;
    margin-left: 20px;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn {
    display: block;
    margin-right: 20px;
    background: linear-gradient(#d9d9d9, #d9d9d9) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat, linear-gradient(90deg, transparent 0%, #f7931e 0%, #fbb03b 88%, #f7931e 97%, #fbb03b 100%);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content {
    border: 1px solid #f7931e;
    width: 12vw;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    padding: 5px;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border {
    border: 2px solid #4d4d4d;
    background-color: #4d4d4d;
    width: 80%;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-up-open {
    position: absolute;
    transform: rotate(-50deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-up-close {
    transform: rotate(0deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-middle-open {
    width: 0%;
    visibility: hidden;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-middle-close {
    width: 75%;
    visibility: visible;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-down-open {
    position: absolute;
    transform: rotate(50deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-down-close {
    transform: rotate(0deg);
  }
  .navbar-container .navbar-content .link-container .link-content-close {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background-color: #d9d9d9;
    top: -150px;
    left: 0;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
    z-index: -20;
  }
  .navbar-container .navbar-content .link-container .link-content-close li {
    display: none;
    margin-top: 7px;
    list-style: none;
  }
  .navbar-container .navbar-content .link-container .link-content-close li a {
    font-size: 5vw;
  }
  .navbar-container .navbar-content .link-container .link-content-close li button {
    font-size: 6vw;
  }
  .navbar-container .navbar-content .link-container .link-content {
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background-color: #d9d9d9;
    top: 100%;
    left: 0;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
  }
  .navbar-container .navbar-content .link-container .link-content li {
    margin-top: 7px;
  }
  .navbar-container .navbar-content .link-container .link-content li a {
    font-size: 5vw;
  }
  .navbar-container .navbar-content .link-container .link-content li button {
    font-size: 6vw;
  }
}
/*************** TABLET ******************/
@media (min-width: 481px) and (max-width: 768px) {
  .navbar-container .navbar-content {
    justify-content: space-between;
  }
  .navbar-container .navbar-content .logo-container {
    width: 20vw;
    margin-left: 20px;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn {
    display: block;
    margin-right: 20px;
    background: linear-gradient(#d9d9d9, #d9d9d9) 50% 50%/calc(100% - 4px) calc(100% - 4px) no-repeat, linear-gradient(90deg, transparent 0%, #f7931e 0%, #fbb03b 88%, #f7931e 97%, #fbb03b 100%);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content {
    border: 1px solid #f7931e;
    width: 12vw;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    padding: 5px;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border {
    border: 2px solid #4d4d4d;
    width: 80%;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-up-open {
    position: absolute;
    transform: rotate(-50deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-up-close {
    transform: rotate(0deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-middle-open {
    width: 0%;
    visibility: hidden;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-middle-close {
    width: 75%;
    visibility: visible;
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-down-open {
    position: absolute;
    transform: rotate(50deg);
  }
  .navbar-container .navbar-content .link-container .nav-media-btn .nav-media-btn-content .nav-border-down-close {
    transform: rotate(0deg);
  }
  .navbar-container .navbar-content .link-container .link-content-close {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background-color: #d9d9d9;
    top: -150px;
    left: 0;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
    z-index: -20;
  }
  .navbar-container .navbar-content .link-container .link-content-close li {
    display: none;
    margin-top: 7px;
    list-style: none;
  }
  .navbar-container .navbar-content .link-container .link-content-close li a {
    font-size: 5vw;
  }
  .navbar-container .navbar-content .link-container .link-content-close li button {
    font-size: 6vw;
  }
  .navbar-container .navbar-content .link-container .link-content {
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background-color: #d9d9d9;
    top: 100%;
    left: 0;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
  }
  .navbar-container .navbar-content .link-container .link-content li {
    margin-top: 7px;
  }
  .navbar-container .navbar-content .link-container .link-content li a {
    font-size: 5vw;
  }
  .navbar-container .navbar-content .link-container .link-content li button {
    font-size: 6vw;
  }
}
/********* SLIDE FLIP TABLET ************/
@media (min-width: 769px) and (max-width: 1024px) {
  .navbar-container .navbar-content .logo-container {
    width: 9vw;
  }
  .navbar-container .navbar-content .link-container .link-content-close {
    width: 80vw;
  }
  .navbar-container .navbar-content .link-container .link-content-close li {
    list-style: none;
  }
  .navbar-container .navbar-content .link-container .link-content-close li a {
    font-size: 1.6vw;
  }
}