@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.about-container {
  width: 100%;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-container .about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  padding: 100px 0px;
}
.about-container .about-content .about-img-box img {
  width: 24vw;
}
.about-container .about-content .about-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}
.about-container .about-content .about-text-box .border-orange {
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  width: 5vw;
  height: 5px;
  margin: 10px 0px;
}
.about-container .about-content .about-text-box h2 {
  color: #4d4d4d;
}
.about-container .about-content .about-text-box p {
  text-align: justify;
  margin-top: 20px;
  color: #4d4d4d;
}

/************ MOBİLE *************/
@media (max-width: 480px) {
  .about-container .about-content {
    flex-direction: column;
    width: 100%;
  }
  .about-container .about-content .about-img-box img {
    width: 80vw;
  }
  .about-container .about-content .about-text-box {
    width: 80%;
  }
  .about-container .about-content .about-text-box .border-orange {
    width: 20vw;
    margin: 30px 0px;
  }
}
/************ TABLET ************/
@media (min-width: 481px) and (max-width: 768px) {
  .about-container .about-content {
    flex-direction: column;
    width: 100%;
  }
  .about-container .about-content .about-img-box img {
    width: 80vw;
  }
  .about-container .about-content .about-text-box {
    width: 80%;
  }
  .about-container .about-content .about-text-box .border-orange {
    width: 20vw;
    margin: 30px 0px;
  }
}
/************ SLIDE FLIP TABLET ****************/
@media (min-width: 769px) and (max-width: 1024px) {
  .about-container .about-content {
    width: 80%;
  }
  .about-container .about-content .about-img-box img {
    width: 35vw;
  }
  .about-container .about-content .about-text-box .border-orange {
    width: 15vw;
  }
}