@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.portfolio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.portfolio-container .portfolio-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 100px 0px;
}
.portfolio-container .portfolio-content .portfolio-text .border-orange {
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  width: 5vw;
  height: 5px;
  margin: 10px 0px;
}
.portfolio-container .portfolio-content .portfolio-text h2 {
  color: #d9d9d9;
}
.portfolio-container .portfolio-content .portfolio-text .ref-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  margin: 50px 0px;
}
.portfolio-container .portfolio-content .portfolio-text .ref-box img {
  width: 5vw;
  margin-right: 20px;
}
.portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text {
  color: #d9d9d9;
  text-align: justify;
}
.portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text h3 {
  color: #fbb03b;
}
.portfolio-container .portfolio-content .portfolio-img img {
  width: 24vw;
}

/******** MOBİLE ********/
@media (max-width: 480px) {
  .portfolio-container .portfolio-content {
    width: 80%;
    flex-direction: column-reverse;
  }
  .portfolio-container .portfolio-content .portfolio-text .border-orange {
    width: 20vw;
    margin: 30px 0px;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box {
    flex-direction: column;
    width: 100%;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box img {
    width: 15vw;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text {
    margin-top: 20px;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text p {
    margin-top: 10px;
  }
  .portfolio-container .portfolio-content .portfolio-img img {
    width: 80vw;
  }
}
/******************** TABLET ******************/
@media (min-width: 481px) and (max-width: 768px) {
  .portfolio-container .portfolio-content {
    width: 80%;
    flex-direction: column-reverse;
  }
  .portfolio-container .portfolio-content .portfolio-text .border-orange {
    width: 20vw;
    margin: 30px 0px;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box {
    flex-direction: column;
    width: 100%;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box img {
    width: 15vw;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text {
    margin-top: 20px;
  }
  .portfolio-container .portfolio-content .portfolio-text .ref-box .ref-text p {
    margin-top: 10px;
  }
  .portfolio-container .portfolio-content .portfolio-img img {
    width: 80vw;
  }
}
/*********** SLIDE FLIP TABLET ************/
@media (min-width: 769px) and (max-width: 1024px) {
  .portfolio-container .portfolio-content {
    width: 80%;
  }
  .portfolio-container .portfolio-content .portfolio-text .border-orange {
    width: 12vw;
  }
  .portfolio-container .portfolio-content .portfolio-img img {
    width: 30vw;
  }
}