@charset "UTF-8";
/******************** MOBİLE ****************************/
/******************** TABLET ****************************/
/******************** SLIDE FLIP TABLET ****************************/
/******************** LAPTOP ****************************/
/******************** LARGE SCREEN **********************/
.header-container {
  width: 100%;
  user-select: none;
}
.header-container .header-content .header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../Assets/Images/header-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(78, 76, 76);
  background-blend-mode: multiply;
  height: 85vh;
}
.header-container .header-content .header-text h1 {
  font-size: 6vw;
  background: -webkit-linear-gradient(#fbb03b, #f7931e);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-container .header-content .header-text h3 {
  font-size: 3.5vw;
  background: -webkit-linear-gradient(#fbb03b, #f7931e);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-container .header-content .header-text p {
  font-size: 3vw;
  background: -webkit-linear-gradient(#fbb03b, #f7931e);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.header-container .header-content .header-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-container .header-content .header-box-container .header-box-content {
  width: 70vw;
  margin: 100px 0px;
}
.header-container .header-content .header-box-container .header-box-content .box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 35vh;
  background-color: #d9d9d9;
  text-align: center;
  position: relative;
  top: 0;
  transition: all 0.3s ease 0s;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box img {
  width: 5vw;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box h4 {
  font-size: 1.5vw;
  color: #4d4d4d;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box p {
  color: #4d4d4d;
  font-weight: 400;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box button {
  background: none;
  border: none;
  outline: none;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box button:hover {
  cursor: pointer;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box button img {
  width: 40px;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box:hover {
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  top: -10px;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box:hover:before {
  display: none;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box:hover > button > img {
  transform: rotate(90deg);
}
.header-container .header-content .header-box-container .header-box-content .box-container .box-border-one::before {
  content: "";
  width: 250px;
  height: 35vh;
  position: absolute;
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  left: -20px;
  top: -20px;
  z-index: -1;
}
.header-container .header-content .header-box-container .header-box-content .box-container .box-border-two::before {
  content: "";
  width: 250px;
  height: 35vh;
  position: absolute;
  background-image: linear-gradient(to right, #fbb03b, #f7931e);
  right: -20px;
  bottom: -20px;
  z-index: -1;
}

/*************** Mobile ****************/
@media (max-width: 480px) {
  .header-container .header-content .header-text {
    background-image: url("../../../Assets/Images/header-mobile.webp");
  }
  .header-container .header-content .header-text h1 {
    font-size: 17vw;
  }
  .header-container .header-content .header-text h3 {
    font-size: 5vw;
  }
  .header-container .header-content .header-text p {
    font-size: 4.5vw;
  }
  .header-container .header-content .header-box-container .header-box-content {
    width: 80vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box img {
    width: 20vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box h4 {
    font-size: 5.5vw;
  }
}
/*************** TABLET ***************/
@media (min-width: 481px) and (max-width: 768px) {
  .header-container .header-content .header-text {
    background-image: url("../../../Assets/Images/header-mobile.webp");
  }
  .header-container .header-content .header-text h1 {
    font-size: 17vw;
  }
  .header-container .header-content .header-text h3 {
    font-size: 5vw;
  }
  .header-container .header-content .header-text p {
    font-size: 4.5vw;
  }
  .header-container .header-content .header-box-container .header-box-content {
    width: 90vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box {
    width: 400px;
    height: 50vh;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box img {
    width: 20vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box h4 {
    font-size: 5.5vw;
  }
}
/******** SLIDE FLIP TABLET **********/
@media (min-width: 769px) and (max-width: 1024px) {
  .header-container .header-content .header-text h1 {
    margin-top: 50px;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box {
    width: 200px;
    height: 60vh;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box img {
    width: 9vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box h4 {
    font-size: 2vw;
  }
  .header-container .header-content .header-box-container .header-box-content .box-container .box::before {
    display: none;
  }
}